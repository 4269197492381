.chart-placeholder {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 250px;
    height: 100%;
    border-radius: 10px;
    background-color: transparent;
    padding: 20px;
    align-self: center;
    justify-self: center;


    .graph {
        align-self: end;
        justify-self: center;
        height: 100%;
        max-height: 150px;
        background-color: #dddedf;
        width: 20px;
        margin-bottom: 0;
    }
    .graph1 {
        animation: width 2.3s infinite
    }

    .graph2 {
        animation: width 2.6s infinite
    }

    .graph3 {
        animation: width 2.9s infinite
    }

    .graph4 {
        animation: width 3.1s infinite
    }

    .graph5 {
        animation: width 3.4s infinite
    }

    @keyframes width {
        0% {
            height: 100%;
        }

        50% {
            height: 20px
        }

        100% {
            height: 100%;
        }
    }
}