// treeselect.scss
.custom-treeselect {
    #rdts1 {
        div {
            width: 100%;
            a {
                display: flex;
                width: 100%;
                height: 35px;                
                // padding: 0.5rem 0.9rem;
                
                align-items: center;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                color: var(--vz-body-color);
                appearance: none;
                background-color: var(--vz-input-bg-custom);
                background-clip: padding-box;
                border: var(--vz-border-width) solid var(--vz-input-border-custom);
                border-radius: var(--vz-border-radius);
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                ul {
                    width: 95%;
                    display: flex;
                    li {
                        width: 100%;
                        .search {
                            border-bottom: none;
                        }
                    }
                }
                .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
                    content: "\e948" !important;
                    vertical-align: middle;
                    color: #3c3c3c;
                    margin-right: 2px;
                }
            }
            .dropdown-content {
                padding: 8px 15px !important;
                .toggle.expanded:after {
                    content: "-" !important;
                    font-size: 18px;
                    font-style: normal !important;
                }
                .toggle.collapsed:after {
                    content: "\002B" !important;
                    font-size: 18px;
                    font-style: normal !important;
                }
                .node {
                    padding: 2px;
                    label {
                        margin-bottom: 0;
                    }

                }
            }
           
        }
    }
    .p-treeselect-panel {
        z-index: 1051;
    }
}