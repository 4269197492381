.custom-google-btn {
  /* Custom styles */
  width: 400px;
  background-color: #4285F4;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-google-btn:hover {
  background-color: #357ae8;
  cursor: pointer;
}
