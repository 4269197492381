.dts-select-dropdown {
    > div {
        width: 100%;
    }
    .react-dropdown-select-dropdown {
        width: 240px !important;
    }
    ul {
        li {
            .btn {
                padding-left: 1.75rem;
                padding-right: 1.75rem;
            }
        }
    }
    .btn-custom {
        max-width: 140px;
    }
    
    .btn-icon-custom {
        height: 34px;
        margin-top: 1px;
    }
    
    .dropdown-content {
        min-width: 124px;
    }
    
    .options-list {
        min-height: 10px;
        max-height: 200px;
        overflow: auto;
        padding: 0;
    }
}